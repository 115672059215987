
.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
.image-upload-wrap {
    /* margin-top: 20px; */
    border: 2px dashed #DDDDDD;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    padding: 10px;
    width: 196px;
    height: 90px;
  }
  
  .image-dropping,
  .image-upload-wrap:hover {
    background-color: whitesmoke;
    border: 4px dashed #ffffff;
  }
  .drag-text {
    text-align: center;
  }
  .drag-text h3 {
    font-weight: 100;
    color: gray;
    margin-top: 5px;
  }
  .upload-icon {
      margin: auto;
      margin-top: 10px;
  }
  .file-upload-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
    /* padding: 20px; */
  }