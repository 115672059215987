.dot {
    height: 8px;
    width: 8px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    margin-right: 2px;
  }

  .title{
    font-size: 10px;
  }