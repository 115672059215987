input::placeholder {
  opacity: 0.8;
}
.ant-layout-sider {
  background-color: #003c7c !important;
}
.ant-select-selector {
  border-radius: 6px;
}
.ant-table table {
  border-collapse: separate;
  background-color: #f2f2f2;
}

.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 8px !important;
}

.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 8px !important;
}

.ant-tooltip-inner :global(.ant-tooltip-inner) {
  color: blue !important;
}


/* 
buat scroll x - horizontal(kanan kiri)
*/
.ant-table-body::-webkit-scrollbar {
  height: 8px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #003c7c;
  border-radius: 4px;
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


/* .ant-table-thead th:first-of-type {
  border-top-left-radius: 8px !important;
} */

/* .ant-table-thead th:last-of-type {
  border-top-right-radius: 8px !important;
} */  

tbody {
  background-color: white !important;
}

thead[class*="ant-table-thead"] th {
  background-color: #e5ebf1 !important;
  border-bottom: 1px solid #dddddd;
}

.ant-table-footer {
  background-color: #e5ebf1 !important;
  padding: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-select-item-option-custom {
  background-color: #E6e6e1 ;
  text-decoration: none;
}

div.ant-message-notice-content {
  border-radius: 8px;
}

.ant-pagination.mini .ant-pagination-item {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.ant-pagination-item {
  background-color: white !important;
  border-color: white !important;
  color: black !important;
}

.ant-pagination-item a {
  color: black !important;
}

.table-row-yellow {
  background-color: #fcf803;
}
.table-row-yellow:hover td {
  background: #fcf803 !important;
}

.table-row-normal {
  background-color: #fbfbfb;
}


.ant-pagination-item-active {
  background-color: #003c7c !important;
  border-color: #003c7c !important;
  color: white !important;
}

.ant-pagination-item-active a {
  color: white !important;
}


.content-wrapper::-webkit-scrollbar-track {
  border-radius: 8px !important;
  background-color: #f6f6f6;
}

.content-wrapper::-webkit-scrollbar {
  border-radius: 8px;
  width: 6px;
  height: 2px;
  background-color: #f6f6f6;
}

.content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #003c7c;
}

.content-wrapper {
  scrollbar-color: #003c7c #f6f6f6;
  scrollbar-width: thin;
  display: block;
}
