.columnRole {
    padding-left: 50px;
}
.content-wrapper {
    scrollbar-color: #003c7c #f6f6f6;
    scrollbar-width: thin;
    overflow: hidden !important;
    display: block;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    border-radius: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #003c7c ;
    background-color: #003c7c ;
}