.ant-table-column-sorters {
  justify-content: flex-start !important;
}

.ant-table-column-title {
  flex: none !important;
}

.ant-select {
  border-radius: 6px;
}
/* my-select-container adalah className buat Div pembungkus select nya */
.my-select-container .ant-select .ant-select-selector {
  border-radius: 6px;
  height: 40px;
}

.my-general-select-container .ant-select .ant-select-selector {
  border-radius: 6px;
  height: 30px;
}

.my-select-container-phone .ant-select .ant-select-selector {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.form-title {
  padding: 8px;
}

.form-input-padding-bottom {
  padding-bottom: 4px;
}

.ant-table {
  border-radius: 20px;
}

.column-po {
  padding: 4px ;
  min-width: 20rem;
}

.list-po {
  padding: 4px 4px 12px 4px;
}
