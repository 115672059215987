.container {
  height: 100vh;
  display: flex;
}

.imgWrapper {
  position: relative;
  height: 100%;
}

.formWrapper {
  padding: 128px 72px;
}

.buttonCancel {
  padding-top: 10px;
}

.formLogo {
  position: relative;
  right: 24px;
  margin-bottom: 8px;
}

.formTitle span {
  font-weight: 500;
  font-size: 20px;
}

.form .formMessage {
  border: 1px solid #13a780;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  margin: 2em 0;
}

.form .formMessage .iconMessage {
  margin-right: 4px;
}

.form .formMessage .descMessage {
  margin-left: 4px;
  font-weight: 500;
}

.form .formErrorMessage {
  border: 1px solid #a71313;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  margin: 2em 0;
}

.form .formErrorMessage .iconMessage {
  margin-right: 4px;
}

.form .formErrorMessage .descMessage {
  margin-left: 4px;
  font-weight: 500;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .imgWrapper {
    width: 0;
  }

  .formWrapper {
    width: 100%;
    padding: 128px 32px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .imgWrapper {
    width: 32%;
  }

  .formWrapper {
    width: 68%;
    padding: 128px 56px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .imgWrapper {
    width: 48%;
  }

  .formWrapper {
    width: 52%;
    padding: 128px 64px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .imgWrapper {
    width: 56%;
  }

  .formWrapper {
    width: 46%;
    padding: 128px 72px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .imgWrapper {
    width: 64%;
  }

  .formWrapper {
    width: 36%;
  }
}