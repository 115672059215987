.modalImport :global(.ant-modal-title) {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    position: relative;
    text-align: center;
  }
  .Drag :global(.ant-upload.ant-upload-drag) {
    background-color: #000000 !important;
  }
  .containerImport {
    /* Frame 3950 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 472px;
    height: 243px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .icon {
    margin-top: 35px;
  }
  .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #666666 !important;
    text-align: center;
  }
  .text2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #666666 !important;
    text-align: center;
  }
  .browserFile {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 24px;
    gap: 10px;
    width: 119px;
    height: 25px;
    /* NexChief/Primary */
    border: 0.707246px solid #783F91;
    border-radius: 3px;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    /* NexChief/Primary */
    color: #783F91;
  }
  .browserFile:hover {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 24px;
    width: 119px;
    height: 25px;
    /* NexChief/Primary */
    border: 0.707246px solid #783F91;
    border-radius: 3px;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    /* NexChief/Primary */
    color: #783F91;
    margin-bottom: 0px;
    margin-top: 0rem;
  }
  .text3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #666666 !important;
    text-align: center;
    margin-top: -3px;
  }
  .text4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #666666 !important;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 4px;
  }
  .text5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #666666 !important;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 15px;
  }
  .FormFieldContainer {
    display: grid;
    grid-column-gap: 24px;
    margin-bottom: 25px !important;
    align-items: center;
    grid-template-columns: 20% 80%;
    justify-items: end;
  }
  .LabelForm {
    display: block;
    text-align: end;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 21px;
    letter-spacing: 0.4px;
    overflow-wrap: anywhere;
  }
  .ContainerLuarFile {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    width: 40%;
    margin-bottom: 20px;
  }
  .ButtonClose,
  .ButtonClose:hover {
    position: relative !important;
    top: -10px;
    background: transparent !important;
    border: none !important;
    display: flex !important;
    padding: 0 !important;
    /* margin: 0px 0px 0px -5px; */
  }
  .ButtonClose svg {
    width: 70%;
  }
  .FormItemUpload {
    margin-bottom: 0 !important;
    width: 60% !important;
  }
  .FormItemUpload :global(.ant-upload.ant-upload-drag) {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='white' stroke='lightgrey' stroke-width='4' stroke-dasharray='10 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
    border: none !important;
  }
  .FormItemUpload :global(.ant-upload.ant-upload-drag) :global(.ant-upload) {
    padding: 16px !important;
  }
  .FormItemUpload :global(.ant-upload.ant-upload-select-picture-card) {
    border: 1px solid #999999;
    border-radius: 4px;
  }
  .FormItemUpload :global(.ant-upload.ant-upload-select-picture-card) > :global(.ant-upload) {
    border: transparent;
  }
  .ContainerBrowseFile {
    border: 1px solid #783f91;
    border-radius: 5px;
    color: #783f91;
    padding: 1px 10px;
    width: fit-content;
    font-size: 14px;
    line-height: 16.41px;
    font-weight: 700;
  }
  .LabelError {
    color: #ff4d4f;
    font-size: smaller;
    display: flex;
    width: 75%;
    margin-top: -10px;
  }
  .ModalFooter {
    text-align: center;
    /* margin-top: -25px; */
    margin-bottom: 30px;
    /* float: ; */
  }
  .cardContainer {
    width: 500;
    height: 250;
    border-radius: 10px;
    border: 4px dashed #cccccc;
    text-align: center;
  }
  .lblText {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #666666;
  }
  .btnBrowse {
    border-radius: 5px;
    border: 3px solid #783F91;
    color: #783F91;
    font-weight: 700;
  }
  .subLblText {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    margin-top: 10px;
  }
  .downloadTemplate {
    text-align: center;
    margin: auto;
    margin-top: 0.75rem;
  }