.Date.DayPicker {
    font-size: 0.8rem;
  }
  
  .Date .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #dae3ff !important;
    color: #000000;
  }
  
  .DayPicker-Caption {
    color: #000000;
    text-align: center;
  }
  
  .Date .DayPicker-Weekday {
    font-weight: 600;
  }
  
  .Date .DayPicker-Weekday:last-child {
    color: #e53e3e !important;
  }
  
  .DayPicker-Day {
    color: #000000;
    border-radius: 15px;
    padding: 0.1rem 0.6rem;
  }
  
  /* color: "#D81922", backgroundColor: "white" */
  .Date .DayPicker-Day--sunday {
    color: #d81922;
    background-color: white;
  }
  
  .Date .DayPicker-Day--start {
    background-color: #e53e3e !important;
    color: #ffffff !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .Date .DayPicker-Day--end {
    background-color: #e53e3e !important;
    color: #ffffff !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }